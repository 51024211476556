let menuHeight = 550;
let isCollapsed = false;
let isReady = false;
let isMovingToTop = false;

let megamenu, fixedHeader, wrapper;

function closeMenu({ hideMegaMenu = false } = {}) {
  console.log("closing menu");
  if (hideMegaMenu) megamenu.classList.add("collapsed-nav");
  fixedHeader.classList.add("collapsed-nav");
  menuHeight = megamenu.offsetHeight;
  console.log(menuHeight);
  isCollapsed = true;
}

function openMenu() {
  console.log("opening menu");
  megamenu.classList.remove("collapsed-nav");
  fixedHeader.classList.remove("collapsed-nav");
  menuHeight = megamenu.offsetHeight;
  console.log(menuHeight);
  isCollapsed = false;
}

function moveToTop() {
  if (isMovingToTop) return;
  isMovingToTop = true;
  setTimeout(() => {
    isMovingToTop = false;
  }, 1000);
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

// Burger menus
document.addEventListener("DOMContentLoaded", function() {
  // open
  const burger = document.querySelectorAll(".navbar-burger");
  const menu = document.querySelectorAll(".navbar-menu");

  if (burger.length && menu.length) {
    for (var i = 0; i < burger.length; i++) {
      burger[i].addEventListener("click", function() {
        for (var j = 0; j < menu.length; j++) {
          menu[j].classList.toggle("hidden");
        }
      });
    }
  }

  // close
  const close = document.querySelectorAll(".navbar-close");
  const backdrop = document.querySelectorAll(".navbar-backdrop");

  if (close.length) {
    for (var i = 0; i < close.length; i++) {
      close[i].addEventListener("click", function() {
        for (var j = 0; j < menu.length; j++) {
          menu[j].classList.toggle("hidden");
        }
      });
    }
  }

  if (backdrop.length) {
    for (var i = 0; i < backdrop.length; i++) {
      backdrop[i].addEventListener("click", function() {
        for (var j = 0; j < menu.length; j++) {
          menu[j].classList.toggle("hidden");
        }
      });
    }
  }

  const toggleSwitch = document.getElementById("toggleSwitch");

  const toggleOptions = document.querySelectorAll(".toggle-option");

  toggleOptions.forEach(option => {
    option.addEventListener("click", function() {
      if (!option.classList.contains("active")) {
        const options = toggleSwitch.querySelectorAll(".toggle-option");
        const slider = toggleSwitch.querySelector(".toggle-slider");

        options.forEach(opt => opt.classList.toggle("active"));

        // Slide the active option
        slider.style.transform =
          option.dataset.option === "en" ? "translateX(0)" : "translateX(100%)";

        const url = option.getAttribute("data-url");
        if (url) {
          window.location.href = url;
        }
      }
    });
  });
});

document.addEventListener("DOMContentLoaded", function() {
  const mobileMenu = document.getElementById("mobile-menu");
  if (!mobileMenu) return;

  const headers = document.querySelectorAll(".menu-header");
  const currentUrl = window.location.href;

  headers.forEach(header => {
    const submenu = header.nextElementSibling;
    const links = submenu ? submenu.querySelectorAll("a") : [];
    const hasLanguageSelector = submenu ? submenu.querySelector(".mobile-language-selector") : null;

    let isActive = false;

    // Mark active link and check if submenu should be expanded
    links.forEach(link => {
      if (link.href === currentUrl && !hasLanguageSelector) {
        link.classList.add("active");
        header.classList.add("active");
        isActive = true; // Mark submenu for expansion
      }
    });

    // Expand submenu if it contains an active link
    if (isActive && submenu) {
      submenu.classList.add("active");
    }

    header.addEventListener("click", function() {
      // Collapse other open submenus
      headers.forEach(h => {
        if (h !== this && h.nextElementSibling) {
          h.nextElementSibling.classList.remove("active");
        }
      });

      // Toggle current submenu
      if (submenu) {
        submenu.classList.toggle("active");
      }
    });
  });
});
